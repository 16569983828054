const apiFetch = (method, path, token, body) => {
    let options = {
        method,
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${token}`,
        },
    }
    if (body) {
        options.body = JSON.stringify(body)
    }
    return fetch(process.env.API + path, options)
        .then(res => res.json())
        .catch(err => ({ error: err.message }))
}

export default {
    name: 'app',
    reducer(state = {}, action) {
        return state
    },
    selectAccountNo(state) {
        return state.auth.data.account
    },
    getExtraArgs(store) {
        // const token = store.selectAccessToken()
        return {
            api: {
                get(path) {
                    return apiFetch('GET', path, store.selectAccessToken())
                },
                post(path, body) {
                    return apiFetch(
                        'POST',
                        path,
                        store.selectAccessToken(),
                        body
                    )
                },
                put(path, body) {
                    return apiFetch(
                        'PUT',
                        path,
                        store.selectAccessToken(),
                        body
                    )
                },
                delete(path, body) {
                    return apiFetch(
                        'DELETE',
                        path,
                        store.selectAccessToken(),
                        body
                    )
                },
            },
        }
    },
}
