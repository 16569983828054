<script>
    import { getContext } from 'svelte';
    import { fly, fade } from 'svelte/transition';
    import BlastContent from './BlastContent.html';

    import Fa from 'svelte-fa'
    import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
    const { open } = getContext('simple-modal');

    export let type = 'small';
    export let buttonName;

    export let blast;

    const options = {
        closeButton: true,
        closeOnEsc: true,
        closeOnOuterClick: true,
        transitionBg: fade,
        transitionBgProps: {
            duration: 500
        },
        transitionWindow: fly,
        transitionWindowProps: {
            y: 100,
            duration: 250
        },
    }
    const showLargeModal = () => {
        open(BlastContent, {blast: blast}, options);
    };
    const showSmallModal = () => {
        open(BlastContent, {blast: blast}, options);
    };
</script>

{#if type == 'large'}
    <a style="margin-left: 5px;" href={'#'} on:click|preventDefault={showLargeModal}>
        <Fa icon={faEnvelopeOpenText} size="lg" color="#6c6c6c"/>
    </a>
{:else}
    <button class="button" on:click={showSmallModal}>{buttonName}</button>
{/if}