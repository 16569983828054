const initial = {
    loading: false,
    data: [],
    error: null,
}

const [BLAST_LOADING, BLAST_CREATED, BLAST_FETCH_SUCCESS, BLAST_ERROR] = [
    'BLAST_LOADING',
    'BLAST_CREATED',
    'BLAST_FETCH_SUCCESS',
    'BLAST_ERROR',
]

export default {
    name: 'blasts',
    doPostBlast(categories, message, spanish) {
        return async ({ dispatch, api, store }) => {
            // dispatch starting
            dispatch({ type: BLAST_LOADING })
            const account = store.selectAccountNo()
            const profile = store.selectProfile()

            const result = await api.post('/blasts', {
                categories,
                message: {
                    en: message,
                    es: spanish,
                },
                account,
                type: 'blast',
                user_id: profile.sub,
                user_name: profile.name,
                status: 'send',
                published: new Date().toISOString(),
            })

            if (result.error) {
                dispatch({ type: BLAST_ERROR, payload: result.error })
                return Promise.resolve(false)
            }

            dispatch({
                type: BLAST_CREATED,
                payload: {
                    _id: result.id,
                    _rev: result.rev,
                    categories,
                    message,
                    account,
                    user_id: profile.sub,
                    user_name: profile.name,
                    status: 'published',
                    published: new Date().toISOString(),
                    type: 'blast',
                },
            })
            // dispatch success
            return Promise.resolve(true)
        }
    },
    doFetchBlasts(page = 1) {
        return async ({ dispatch, api, store }) => {
            // dispatch starting
            dispatch({ type: BLAST_LOADING })
            const accountNo = store.selectAccountNo()
            const result = await api.get(
                `/blasts?account=${accountNo}&page=${page}`
            )
            // dispatch success
            if (result.error) {
                dispatch({ type: BLAST_ERROR, payload: result.error })
                return Promise.resolve(false)
            }
            // dispatch({ type: BLAST_FETCH_SUCCESS, payload: sortBlastsByDate(result, desc=true)})
            dispatch({ type: BLAST_FETCH_SUCCESS, payload: result})
            return Promise.resolve(true)
        }
    },
    selectBlasts(state) {
        return state.blasts.data
    },
    selectBlastLoading(state) {
        return state.blasts.loading
    },
    selectBlastError(state) {
        return state.blasts.error
    },
    reducer(state = initial, { type, payload }) {
        if (type === BLAST_LOADING) {
            return merge(state, { loading: true })
        }
        if (type === BLAST_ERROR) {
            return merge(state, { loading: false, error: payload })
        }
        if (type === BLAST_FETCH_SUCCESS) {
            return merge(state, { loading: false, data: payload })
        }
        if (type === BLAST_CREATED) {
            const data = prepend(payload, state.data)
            return merge(state, { loading: false, data })
        }

        return state
    },
}

function merge(...args) {
    return Object.assign({}, ...args)
}

function prepend(v, arr) {
    return [v, ...arr]
}
