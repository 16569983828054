const initial = {
  loading: false,
  data: [],
  error: null
}

import { contains, filter, append } from 'ramda'

const [
  USERS_LOADING,
  USERS_ERROR,
  USERS_FETCH_SUCCESS,
  USERS_CREATE_SUCCESS,
  USERS_DELETED_SUCCESS
] = [
  'USERS_LOADING',
  'USERS_ERROR',
  'USERS_FETCH_SUCCESS',
  'USERS_CREATE_SUCCESS',
  'USERS_DELETED_SUCCESS'
]

export default {
  name: 'users',
  doGetUsers() {
    return async ({ dispatch, api, store }) => {
      // dispatch starting
      dispatch({ type: USERS_LOADING })
      const accountNo = store.selectAccountNo()
      const result = await api.get(`/users/${accountNo}`)
      // dispatch success
      if (result.error) {
        dispatch({ type: USERS_ERROR, payload: result.error })
        return Promise.resolve(false)
      }
      dispatch({ type: USERS_FETCH_SUCCESS, payload: result })
      return Promise.resolve(true)
    }
  },
  doAddUser({ email, admin }) {
    return async ({ dispatch, api, store }) => {
      // dispatch starting
      dispatch({ type: USERS_LOADING })
      const accountNo = store.selectAccountNo()
      const account = store.selectAccount()
      const users = store.selectUsers()

      const result = await api.post('/users/create', {
        tenant: account.city.toLowerCase(),
        account: accountNo,
        email,
        isAdmin: admin
      })

      // dispatch success
      if (result.error) {
        dispatch({ type: USERS_ERROR, payload: result.error })
        return Promise.resolve(false)
      }

      const data = await append(result.user, users)

      dispatch({ type: USERS_CREATE_SUCCESS, payload: data })
      return Promise.resolve(true)
    }
  },
  doDeleteUser(userIds) {
    return async ({ dispatch, api, store }) => {
      const users = store.selectUsers()
      // dispatch starting
      dispatch({ type: USERS_LOADING })

      const result = await api.delete(`/users/delete`, userIds)

      // dispatch success
      if (result.error) {
        dispatch({ type: USERS_ERROR, payload: result.error })
        return Promise.resolve(false)
      }

      const data = filter((user) => {
        if (!contains(user.user_id, userIds)) {
          return user
        }
      }, users)

      dispatch({ type: USERS_DELETED_SUCCESS, payload: data })
      return Promise.resolve(true)
    }
  },
  selectUsers(state) {
    return state.users.data
  },
  selectUsersLoading(state) {
    return state.users.loading
  },
  reducer(state = initial, { type, payload }) {
    if (type === USERS_LOADING) {
      return merge(state, { loading: true })
    }
    if (type === USERS_ERROR) {
      return merge(state, { loading: false, error: payload })
    }
    if (type === USERS_DELETED_SUCCESS) {
      return merge(state, {
        loading: false,
        data: payload
      })
    }
    if (type === USERS_CREATE_SUCCESS) {
      return merge(state, {
        loading: false,
        data: payload
      })
    }
    if (type === USERS_FETCH_SUCCESS) {
      return merge(state, {
        loading: false,
        data: payload
      })
    }
    return state
  },
  persistActions: [USERS_FETCH_SUCCESS]
}

function merge(...args) {
  return Object.assign({}, ...args)
}
