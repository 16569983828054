<script>
    import { onMount, getContext, tick } from 'svelte'
    import { formatDistance } from 'date-fns'
    import { pathOr, isEmpty } from 'ramda'
    import Logo from '../components/Logo.html'

    import Fa from 'svelte-fa'
    import { faSortAmountDown, faSortAmountDownAlt, faSortAmountUp, faSortAmountUpAlt} from '@fortawesome/free-solid-svg-icons'

    import Modal from 'svelte-simple-modal';
    import BlastModal from '../components/modals/blasts/BlastModal.html';

    const iconTheme = {
        secondaryOpacity: 1,
        primaryColor: '#52c2e4',
        secondaryColor: '#6c6c6c',
    }

    const store = getContext('store')
    const action = getContext('action')

    $: blasts = $store.blasts
    $: account = $store.account
    $: categories = $store.categories
    $: isAdmin = $store.isAdmin

    let dateDesc = true

    const [READY, SUBMITTING, ERROR, SUCCESS, LOADING] = [
        'READY',
        'SUBMITTING',
        'ERROR',
        'SUCCESS',
        'LOADING',
    ]

    let componentState = LOADING

    onMount(async () => {
        const res = await action('doGetAccount')()
        await action('doCheckSpanish')()
        if (!res) {
            componentState = ERROR
            return
        }

        await action('doFetchBlasts')()
        sortBlastsByDate()
        setTimeout(function() {
            componentState = READY
        }, 1000)
        //componentState = READY
    })

    const doLogout = async () => {
        if (confirm('Are you sure you want to log out?')) {
            componentState = SUBMITTING
            await action('doLogout')()
            await action('doUpdateUrl')('/loggedout')
        }
    }

    function truncateText(str, max) {
        return str.length > max ? str.substr(0, max-1) + '…' : str;
    }

    function toggleDateSort() {
        if (blasts !== undefined && blasts.length !== 0) {
            dateDesc = !dateDesc
            sortBlastsByDate()
        }
    }

    function sortBlastsByDate() {
        blasts.sort(function(a, b){
            const dateA = new Date(pathOr(null, ['published'], a));
            const dateB = new Date(pathOr(null, ['published'], b));
            return dateDesc ? dateB - dateA : dateA - dateB;
        })
        blasts = blasts
    }
</script>

<style>
    section {
        height: 100%;
        display: grid;
        grid-template-areas:
            'header'
            'main'
            'footer';

        grid-template-rows: 72px auto 48px;
    }
    header {
        grid-area: header;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }
    header > div {
        height: 100%;
    }
    .title {
        color: white;
    }
    .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    main {
        grid-area: main;
        display: flex;
        flex-direction: column;
    }
    .box {
        min-width: 550px;
    }
    footer {
        grid-area: footer;
    }
    button:hover {
        background-color: #e7e7e7;
    }
    .tooltip {
        position:relative; /* making the .tooltip span a container for the tooltip text */
        /* border-bottom:1px dashed #000; /* little indicater to indicate it's hoverable */
    }

    .tooltip:before {
        content: attr(data-text); /* here's the magic */
        position:absolute;

        /* vertically center */
        top:50%;
        transform:translateY(-50%);

        /* move to right */
        left:100%;
        margin-left:15px; /* and add a small left margin */

        /* basic styles */
        width:200px;
        padding:10px;
        border-radius:10px;
        background:#000;
        color: #fff;
        text-align:center;

        display:none; /* hide by default */

        opacity:0;
        transition:.3s opacity;
    }

    .tooltip:hover:before {
        opacity:1;
        display:block;
    }

    .tooltip:after {
        content: "";
        position:absolute;

        /* position tooltip correctly */
        left:100%;
        margin-left:-5px;

        /* vertically center */
        top:50%;
        transform:translateY(-50%);

        /* the arrow */
        border:10px solid #000;
        border-color: transparent black transparent transparent;

        display:none;

        opacity:0;
        transition:.3s;
    }

    .tooltip:hover:after {
        display:block;
        opacity:1;
    }
</style>

<section>
    {#if componentState === LOADING}
        <main class="center">
            <div class="content">
                <img src="/loading.svg" alt="loading..." />
            </div>
        </main>
    {/if}

    {#if componentState === READY}
        <div class="navbar is-spaced is-primary">
            <div class="navbar-brand">
                <h1 class="title">{account.description}</h1>
            </div>
            <div class="navbar-menu">
                <div class="navbar-end is-pulled-right">

                    {#if isAdmin}
                        <div class="navbar-item">
                            <a class="button is-primary" href="/admin">
                                Dashboard
                            </a>
                        </div>
                    {/if}
                    <div class="navbar-item">
                        <button
                            on:click|preventDefault={doLogout}
                            class=" button is-primary">
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <main class="center">
            <div class="content">
                <h3 class="center">Blast Notifications</h3>
                <div class="box">
                    <nav>
                        <a href="/blasts/new" class="button">New Blast</a>
                        <a href="/notify/new" class="button">Direct Message</a>
                        <a href="/citizen/new" class="button is-pulled-right">
                            Add Numbers
                        </a>
                    </nav>
                    <br>
                    {#if blasts && categories}
                        <table class="table">
                            <thead>
                                <tr>
                                    <th />
                                    <th>Sent
                                        {#if blasts !== undefined && blasts.length != 0}
                                            <a style="margin-left: 5px;" href={'#'} on:click|preventDefault={() => toggleDateSort()}>
                                                <Fa icon={dateDesc ? faSortAmountDown : faSortAmountUp } size="s" {...iconTheme} />
                                            </a>
                                        {/if}
                                    </th>
                                    <th>Categories</th>
                                    <th>Sent by</th>
                                    <th>Sent to</th>
                                </tr>
                            </thead>
                            <tbody>
                                {#each blasts as blast}
                                    <tr>
                                        <td>
                                            <Modal>
                                                <BlastModal
                                                        type = 'large'
                                                        buttonName='Open'
                                                        blast = {blast}
                                                ></BlastModal>
                                            </Modal>
                                        </td>
                                        <td>
                                            {formatDistance(new Date(blast.published), new Date())}
                                        </td>
                                        <td>
                                            <span class="tooltip"
                                                  data-text={truncateText(pathOr('', ['message', 'en'], blast), 50)}
                                            >
                                                {blast.categories.join(', ')}
                                            </span>
                                        </td>
                                        <td>{blast.user_name}</td>
                                        <td>
                                            {blast.citizenCount ? blast.citizenCount : '0'}
                                        </td>

                                    </tr>
                                {/each}
                            </tbody>
                        </table>
                    {:else}
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Sent</th>
                                    <th>Sent by</th>
                                    <th>Sent to</th>
                                </tr>
                            </thead>
                            <tbody>
                                {#each blasts as blast}
                                    <tr>
                                        <td>
                                            {formatDistance(new Date(blast.published), new Date())}
                                        </td>
                                        <td>{blast.user_name}</td>
                                        <td>{blast.citizenCount}</td>
                                    </tr>
                                {/each}
                            </tbody>
                        </table>
                    {/if}
                </div>
            </div>

        </main>
    {:else if componentState === ERROR}
        <header class="navbar is-primary center">
            <div class="navbar-brand center">
                <h1 class="title">Citibot Notify</h1>
            </div>

        </header>
        <main class="center">
            <Logo />
            <div class="content">
                <h3 class="center">Something Went Wrong</h3>
                <div class="box">
                    <p>
                        Please double check your credentials and try again.
                        <br />
                        If the problem perists contact support at
                        info@citibot.io
                    </p>
                    <button
                        on:click|preventDefault={() => action('doLogout')()}
                        class="center button is-primary">
                        close
                    </button>
                </div>
            </div>
        </main>
    {/if}
    <footer class="center">
        <p>All Rights Reserved. &copy; Citibot, LLC 2020</p>
    </footer>
</section>
