import { WebAuth } from 'auth0-js'
import cache from '../utils/cache'

let auth0 = null

// states
const [
  AUTH0_SENT_CODE,
  AUTH0_GET_PROFILE,
  AUTH0_SUCCESS,
  AUTH0_ERROR,
  AUTH0_LOGOUT,
  ,
] = [
  'AUTH0_SENT_CODE',
  'AUTH0_GET_PROFILE',
  'AUTH0_SUCCESS',
  'AUTH0_ERROR',
  'AUTH0_LOGOUT'
]

const initialState = {
  loading: false,
  data: {},
  status: 'AUTH0_INIT'
}

auth0 = new WebAuth({
  domain: 'auth.citibot.io',
  clientID: 'aqXRSSWoX6mjq0GiPCXSNW3QagujTFD7',
  responseType: 'token id_token',
  redirectUri: window.location.origin + '/callback',
  scope: 'openid profile admin:on read:users create:users update:users',
  audience: 'https://api.citibot.io'
})

export default {
  name: 'auth',
  doAuthLogin() {
    return ({ dispatch }) =>
      new Promise((resolve, reject) => {
        dispatch({ type: AUTH0_GET_PROFILE })
        // get accessToken
        auth0.parseHash({ hash: window.location.hash }, (err, authResult) => {
          if (err) {
            dispatch({
              type: AUTH0_ERROR,
              payload: err.message
            })
          }
          // get user
          auth0.client.userInfo(authResult.accessToken, (err, user) => {
            if (!user['https://notify.dev/tenant']) {
              dispatch({
                type: AUTH0_ERROR,
                payload: 'You are not authorized to use this application.'
              })
              return resolve(false)
            }
            if (err) {
              dispatch({
                type: AUTH0_ERROR,
                payload: err.message
              })
              return resolve(false)
            }
            dispatch({
              type: AUTH0_SUCCESS,
              payload: {
                accessToken: authResult.accessToken,
                profile: user,
                account: user['https://notify.dev/account']
              }
            })
            resolve(true)
          })
        })
      })
  },
  doRenewToken() {
    return ({ dispatch }) =>
      new Promise((resolve, reject) => {
        // TODO
      })
  },
  doLogout() {
    return ({ dispatch }) =>
      new Promise((resolve, reject) => {
        auth0.logout({
          returnTo: window.location.origin + '/loggedout'
        })
        dispatch({
          type: AUTH0_LOGOUT,
          payload: { loading: true, loggedIn: false }
        })
        cache.clear()
        resolve(true)
      })
  },
  doAuthVerify({ email, code }) {
    return ({ dispatch }) => {
      // TODO: Validate inputs
      return new Promise((resolve, reject) => {
        auth0.passwordlessVerify(
          {
            connection: 'email',
            email,
            verificationCode: code
          },
          (err, res) => {
            if (err) {
              dispatch({
                type: AUTH0_ERROR,
                payload: err.message
              })
              return resolve(false)
            }
            // no need to dispatch
            // redirect will occur
            resolve(true)
          }
        )
      })
    }
  },
  doAuthStart({ email }) {
    return ({ dispatch }) => {
      // verify email
      if (!email) {
        dispatch({ type: AUTH0_ERROR, payload: 'email is required!' })
        return resolve(false)
      }
      // TODO: validate email???

      return new Promise((resolve, reject) => {
        auth0.passwordlessStart(
          {
            connection: 'email',
            send: 'code',
            email
          },
          (err, res) => {
            if (err) {
              dispatch({
                type: AUTH0_ERROR,
                payload: err.message
              })
              resolve(false)
              return
            }
            dispatch({ type: AUTH0_SENT_CODE, payload: true })
            resolve(true)
          }
        )
      })
    }
  },
  selectIsLoggedIn(state) {
    return Boolean(state.auth.data.accessToken)
  },
  selectProfile(state) {
    return state.auth.data.profile
  },
  selectIsAdmin(state) {
    if (state.auth.data.profile) {
      return Boolean(state.auth.data.profile['https://notify.dev/isAdmin'])
    } else {
      return null
    }
  },
  selectAccessToken(state) {
    return state.auth.data.accessToken
  },
  reducer(state = initialState, { type, payload }) {
    if (type === AUTH0_SENT_CODE) {
      return merge(state, { status: type })
    }
    if (type === AUTH0_ERROR) {
      return merge(state, {
        loading: false,
        error: payload,
        status: type
      })
    }
    if (type === AUTH0_SUCCESS) {
      return merge(state, { loading: false, data: payload, status: type })
    }
    if (type === AUTH0_LOGOUT) {
      return merge(state, { loading: false, data: {}, status: type })
    }

    return state
  },
  persistActions: [AUTH0_SUCCESS]
}

function merge(...args) {
  return Object.assign({}, ...args)
}
