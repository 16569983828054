/*
 * added create selector - this is used to pull data from
 * redux bundle selectors for use with another selector
 *
 */
import { createSelector } from 'redux-bundler'

import Start from '../pages/Start.html'
import About from '../pages/About.html'
import Login from '../pages/Login.html'
import Auth from '../pages/Auth.html'
import Blasts from '../pages/Blasts.html'
import BlastForm from '../pages/BlastForm.html'
import NotifyForm from '../pages/NotifyForm.html'
import Success from '../pages/Success.html'
import CitizenForm from '../pages/CitizenForm.html'
import Admin from '../pages/Admin.html'
import Unauthorized from '../pages/Unauthorized.html'
import NotFound from '../pages/NotFound.html'
import LogoutSuccess from '../pages/LogoutSuccess.html'
//import BlastDetail from '../pages/BlastDetail.html'

/*
 *
 * routes map object, this object contains
 * all the routes matchers to their svelte pages
 */
const routes = {
    '/': Start,
    '/login': Login,
    '/about': About,
    '/callback': Auth,
    '/blasts': Blasts,
    '/blasts/new': BlastForm,
    //'/blasts/detail': BlastDetail,
    '/notify/new': NotifyForm,
    '/citizen/new': CitizenForm,
    '/unauthorized': Unauthorized,
    '/notfound': NotFound,
    '/admin': Admin,
    '/success': Success,
    '/loggedout': LogoutSuccess,
}

/**
 * securePaths is an array that contains all the paths that should
 * be secure, if the user is not logged in then these paths should
 * not be accessible.
 */
const securePaths = [
    '/blasts',
    '/blasts/new',
    '/notify/new',
    '/citizen/new',
    '/admin',
    '/success',
]

export default {
    name: 'routes',
    /**
     * select route will pass the current page based on the url
     * to the route selector which will drive the current page
     * on App.svelte
     *
     * It uses two selectors, selectPathname and selectIsLoggedIn
     *
     * - selectPathname returns the current path for the app
     * - selectIsLoggedIn returns a true or false, if the user
     *   is logged in, it returns true otherwise false
     */
    selectRoute: createSelector(
        'selectPathname',
        'selectIsLoggedIn',
        'selectIsAdmin',
        (pathname, isLoggedIn, isAdmin) => {
            // if not logged in and the request is a secure path return
            // Unauthorized page
            if (
                (!isLoggedIn && securePaths.includes(pathname)) ||
                (pathname === '/admin' && !isAdmin)
            ) {
                return Unauthorized
            }

            // is route is not found in matchers this send to unauthorizedd
            // might want to change to a not found page??
            if (!routes[pathname]) {
                return NotFound
            }
            // route to the matched path.
            return routes[pathname]
        }
    ),
}
