import categoryData from '../lib/category_data.js'
import { pathOr } from 'ramda'

const initialState = {
    loading: false,
    data: categoryData,
    spanish: false,
}

const [CHECK_SPANISH, SPANISH_TRUE, SPANISH_FALSE] = [
    'CHECK_SPANISH',
    'SPANISH_TRUE',
    'SPANISH_FALSE',
]

// TODO: pull categories from account
export default {
    name: 'categories',
    doCheckSpanish() {
        return async ({ dispatch, store }) => {
            // dispatch starting
            dispatch({ type: CHECK_SPANISH })
            const account = store.selectAccount()
            const spanish = pathOr(
                null,
                ['notifyData', 'categories', 'spanish'],
                account
            ) // dispatch success
            if (spanish) {
                dispatch({ type: SPANISH_TRUE })
                return Promise.resolve(true)
            } else {
                dispatch({ type: SPANISH_FALSE })
                return Promise.resolve(true)
            }
        }
    },
    reducer(state = initialState, { type, payload }) {
        if (type === CHECK_SPANISH) {
            return merge(state, { loading: true })
        }
        if (type === SPANISH_TRUE) {
            return merge(state, { loading: false, spanish: true })
        }
        if (type === SPANISH_FALSE) {
            return merge(state, { loading: false, spanish: false })
        }
        return state
    },
    selectCategories(state) {
        const account = store.selectAccount()
        const categories = pathOr(
            null,
            ['notifyData', 'categories', 'english'],
            account
        )
        return categories
    },
    selectSpanish(state) {
        return state.categories.spanish
    },
}

function merge(...args) {
    return Object.assign({}, ...args)
}
