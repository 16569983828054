const initial = {
    loading: false,
    data: {},
}

const [
    CITIZEN_LOADING,
    CITIZEN_ERROR,
    CITIZEN_FETCH_SUCCESS,
    CITIZEN_CREATED,
] = [
    'CITIZEN_LOADING',
    'CITIZEN_ERROR',
    'CITIZEN_FETCH_SUCCESS',
    'CITIZEN_CREATED',
]

export default {
    name: 'citizens',
    doGetCitizens() {
        return async ({ dispatch, api, store }) => {
            // dispatch starting
            dispatch({ type: CITIZEN_LOADING })
            const accountNo = store.selectAccountNo()
            const result = await api.get(`/citizens/optin/${accountNo}`)

            // dispatch success
            if (result.error) {
                dispatch({ type: CITIZEN_ERROR, payload: result.error })
                return Promise.resolve(false)
            }
            dispatch({ type: CITIZEN_FETCH_SUCCESS, payload: result })
            return Promise.resolve(true)
        }
    },
    doPostCitizen(phoneNumber, categories) {
        ///TODO: ADD PHONE NUMBER VALIDATOR
        return async ({ dispatch, api, store }) => {
            dispatch({ type: CITIZEN_LOADING })
            const account = store.selectAccountNo()
            const result = await api.post('/citizens', {
                _id: phoneNumber,
                citizen: phoneNumber,
                account,
                type: 'citizen',
                language: 'english',
                categories,
                dateCreated: new Date().toISOString(),
            })

            if (result.error) {
                dispatch({ type: CITIZEN_ERROR, payload: result.error })
                return Promise.resolve(false)
            }
            dispatch({
                type: CITIZEN_CREATED,
                payload: {
                    _id: result.id,
                    _rev: result.rev,
                    citizen: phoneNumber,
                    account,
                    type: 'citizen',
                    language: 'english',
                    categories,
                    dateCreated: new Date().toISOString(),
                },
            })
            // dispatch success
            return Promise.resolve(true)
        }
    },
    selectCitizens(state) {
        return state.citizens.data
    },
    reducer(state = initial, { type, payload }) {
        if (type === CITIZEN_LOADING) {
            return merge(state, { loading: true })
        }
        if (type === CITIZEN_ERROR) {
            return merge(state, { loading: false, error: payload })
        }
        if (type === CITIZEN_FETCH_SUCCESS) {
            return merge(state, { loading: false, data: payload })
        }
        if (type === CITIZEN_CREATED) {
            const data = prepend(payload, state.data)
            return merge(state, { loading: false, data })
        }

        return state
    },
    persistActions: [CITIZEN_FETCH_SUCCESS],
}

function merge(...args) {
    return Object.assign({}, ...args)
}

function prepend(v, arr) {
    return [v, ...arr]
}
