<script>
    import Logo from '../components/Logo.html'
    import { onMount, getContext } from 'svelte'

    const store = getContext('store')
    const action = getContext('action')
    onMount(() => {
        if ($store.accessToken) {
            // redirect to blasts
            action('doUpdateUrl')('/blasts')
        }
    })
</script>

<style>
    section {
        height: 100%;
    }
    .box {
        max-width: 500px;
    }
    .center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .button {
        margin-top: 16px;
    }
</style>

<section class="section center">
    <Logo />
    <h1 class="title">Citibot Notify</h1>
    <h2 class="subtitle">
        Send notifications about news and events to your residents with ease.
    </h2>
    <div class="box center">
        <p>
            Welcome to the Citibot Notify Application. This application is for
            local government to broadcast communications to their citizens. In
            order to access this application, you will need your email address
            and to have authorization by your team.
        </p>
        <nav>
            <a class="button is-primary" href="/login">Login</a>
            <a class="button is-primary" href="/about">About</a>
        </nav>
    </div>
</section>
