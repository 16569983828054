import App from './App.svelte';
import cache from './utils/cache'
if (/localhost/.test(window.location.href)) {
  window.localStorage.setItem('debug', 'true')
}

cache.getAll().then(initialData => {
  if (initialData) {
    console.log('starting with locally cache data: ', initialData)
  }

  const app = new App({
	  target: document.body,
    props: {
      initialData
    }
  });

})

export default app;
