import { head } from 'ramda'

export default (str) => {
    console.log('hit phone validator')
    if (str === null) {
        str = ''
    }

    if (head(str) === '1') {
        const newStr = str.slice([1])
        str = newStr
    }

    const regExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    const phone = str.match(regExp)

    return phone === null || str === '' ? null : '1' + str
}
