<script>
    import { tick, getContext, afterUpdate, onMount } from 'svelte'
    import { isEmpty, not, pathOr } from 'ramda'

    const action = getContext('action')
    const store = getContext('store')

    // state
    let selected = []
    let categories = $store.categories
    let message = ''
    let spanish = ''
    let spanishEnabled = $store.spanish
    let error = ''
    let account = $store.accountNo
    let blastError = $store.blastError
    let accountDoc = $store.account

    //states component can be in
    // ready, submitting, error, success
    const [READY, SUBMITTING, ERROR, SUCCESS] = [
        'READY',
        'SUBMITTING',
        'ERROR',
        'SUCCESS',
    ]

    let componentState = READY

    $: charCount = message.length
    $: spanishCharCount = spanish.length
    $: {
        if (componentState === ERROR) {
            alert(error)
            componentState = READY
        }
        if (componentState === SUCCESS) {
            tick()
            action('doUpdateUrl')('/success')
        }
    }

    async function doSubmit() {
        if (
            selected.length === 0 &&
            categories !== null &&
            not(isEmpty(categories))
        ) {
            error = 'Must select a category'
            componentState = ERROR
            return
        }
        if (charCount === 0) {
            error = 'Cannot send empty message'
            componentState = ERROR
            return
        }

        // change component state to submitting
        componentState = SUBMITTING

        const success = await action('doPostBlast')(
            selected,
            enhance(message, account),
            enhance(spanish, account)
        )

        if (!success) {
            // TODO: get error from blast bundle?

            error =
                'Sorry, an error occured. Please make sure you have at least one citizen signed up.'
            componentState = ERROR
            return
        } else {
            componentState = SUCCESS
        }
    }

    function enhance(msg, account) {
        const enhancement = pathOr(
            null,
            ['notifyData', 'blastEnhancement'],
            accountDoc
        )
        if (enhancement) {
            return `${msg}

${enhancement}`
        } else {
            return `${msg}`
        }
    }
</script>

<style>
    .category-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-wrap: wrap;
    }

    section {
        height: 100%;
        display: grid;
        grid-template-areas:
            'header'
            'main'
            'footer';

        grid-template-rows: 72px auto 48px;
    }

    header {
        grid-area: header;
    }

    header > div {
        height: 100%;
    }
    .title {
        color: white;
    }

    .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    main {
        grid-area: main;
        display: flex;
        flex-direction: column;
    }

    .box {
        margin-top: 32px;
        max-width: 750px;
    }

    @media (max-width: 500px) {
        .box {
            max-width: 400px;
        }
    }

    footer {
        grid-area: footer;
    }

    textarea {
        border-color: rgba(0, 0, 0, 0.04);
    }
</style>

<section>
    <header class="navbar is-primary center">
        <div class="navbar-brand center">
            <h1 class="title">{accountDoc.description}</h1>
        </div>
    </header>
    {#if categories === null || isEmpty(categories)}
        <main class="center">
            <div class="content">
                <div class="box">
                    <h3 class="subtitle">Send Citibot Notices</h3>
                    <p>
                        Write your message. You can include links to additional
                        resources, but you should aim to keep the message under
                        160 characters.
                    </p>
                    <p>
                        If you exceed that limit, you message will be split into
                        multiple texts.
                    </p>

                    <form on:submit|preventDefault={doSubmit}>
                        <div class="columns">
                            <div class="column is-half">
                                <div class="field">
                                    <label for="charCount2" class="label">
                                        Message
                                    </label>
                                    <textarea
                                        class="textarea"
                                        bind:value={message} />
                                    <span>{charCount} characters entered.</span>
                                </div>
                            </div>
                            {#if spanishEnabled}
                                <div class="column is-half">
                                    <div class="field">
                                        <label for="spanish2" class="label">
                                            Spanish
                                        </label>
                                        <textarea
                                            class="textarea"
                                            bind:value={spanish} />
                                        <span>
                                            {spanishCharCount} characters
                                            entered.
                                        </span>
                                    </div>
                                </div>
                            {/if}
                        </div>

                        <div>
                            <button
                                disabled={componentState === SUBMITTING}
                                class="button is-primary"
                                type="submit">
                                Send
                            </button>
                            <a href="/blasts" class="button">Cancel</a>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    {:else}
        <main class="center">
            <div class="content">
                <div class="box">
                    <h3 class="subtitle">Send Citibot Notices</h3>
                    <p>
                        In order to send a blast notification, you need to
                        select the category or categories that you want to send
                        the blast to.
                    </p>
                    <p>
                        Next, you will want to write the message. You can
                        include links to additional resources, but you should
                        aim to keep the message under 160 characters.
                    </p>
                    <p>
                        If you exceed that limit, you message will be split into
                        multiple texts.
                    </p>

                    <form on:submit|preventDefault={doSubmit}>
                        <div class="field">
                            <label class="label" for="checkbox">
                                Categories
                            </label>
                            <div class="category-list">
                                {#each categories as category}
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={category.key}
                                            bind:group={selected} />
                                        <span>{category.title}</span>
                                    </label>
                                {/each}
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-half">
                                <div class="field">
                                    <label for="charCount" class="label">
                                        Message
                                    </label>
                                    <textarea
                                        class="textarea"
                                        bind:value={message} />
                                    <span>{charCount} characters entered.</span>
                                </div>
                            </div>
                            {#if spanishEnabled}
                                <div class="column is-half">
                                    <div class="field">
                                        <label for="spanish" class="label">
                                            Spanish
                                        </label>
                                        <textarea
                                            class="textarea"
                                            bind:value={spanish} />
                                        <span>
                                            {spanishCharCount} characters
                                            entered.
                                        </span>
                                    </div>
                                </div>
                            {/if}
                        </div>
                        <div>
                            <button
                                disabled={componentState === SUBMITTING}
                                class="button is-primary"
                                type="submit">
                                Send
                            </button>
                            <a href="/blasts" class="button">Cancel</a>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    {/if}
    <footer class="center">
        <p>All Rights Reserved. &copy; Citibot, LLC 2020</p>
    </footer>
</section>
