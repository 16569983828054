export default {
  name: "notification",
  doNotify(phone, message) {
    return async ({ dispatch, api, store }) => {
      dispatch({ type: "DIRECT_NOTIFY_STARTED" });
      const results = await api.post("/notifications", {
        type: "notification",
        from: store.selectAccountNo(),
        to: "1" + phone,
        reply: "",
        message
      });
      if (results.error) {
        dispatch({
          type: "DIRECT_NOTIFY_ERROR",
          payload: results.error
        });
        return Promise.resolve(false);
      }
      dispatch({ type: "DIRECT_NOTIFY_SUCCESS", payload: results });
      return Promise.resolve(true);
    };
  },
  reducer(
    state = { submitting: false, data: {}, error: "" },
    { type, payload }
  ) {
    if (type === "DIRECT_NOTIFY_STARTED") {
      return merge(state, { submitting: true });
    }
    if (type === "DIRECT_NOTIFY_ERROR") {
      return merge(state, { submitting: false, error: payload });
    }
    if (type === "DIRECT_NOTIFY_SUCCESS") {
      return merge(state, { submitting: false, data: payload });
    }
    return state;
  }
};

function merge(...args) {
  return Object.assign({}, ...args);
}
