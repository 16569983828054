<script>
  import { onMount } from 'svelte';
  import { pathOr, isEmpty } from 'ramda'

  export let blast;

  let published = '';
  let user_name = '';
  let citizenCount = 0;

  let categories = '';
  let englishSpeakers = 0
  let spanishSpeakers = 0

  let message = '';

  onMount(() => {

    let categoryList = pathOr([], ['categories'], blast)
    categories = isEmpty(categoryList) ? '' : categoryList.join(', ')

    published = pathOr('', ['published'], blast)
    published = new Date(published).toDateString()
    
    user_name = pathOr('', ['user_name'], blast)

    citizenCount = pathOr(0, ['citizenCount'], blast)

    englishSpeakers = pathOr(0, ['englishSpeakers'], blast)
    spanishSpeakers = pathOr(0, ['spanishSpeakers'], blast)

    message = pathOr('** No Message **', ['message', 'en'], blast)

	});

</script>

<h1>Details</h1>

<p>
  Sent on <b>{published}</b> by <b>{user_name}</b><br>
  This blast was sent to {citizenCount} resident(s)
</p>

<p>
  <b>Categories:&nbsp;&#09;</b>{categories}<br>
  <b>English Speakers:&nbsp;&#09;</b>{englishSpeakers}<br>
  <b>Spanish Speakers:&nbsp;&#09;</b>{spanishSpeakers}
</p>

<h2>Message</h2>
<p>{message}</p>
<br><br>

<style>
    h1 {
        font-size: 2rem;
        margin-bottom: 1em;
      }
    h2 {
        font-size: 1.25rem;
      }
</style>