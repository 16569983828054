<script>
    import { onMount, getContext } from 'svelte'
    import Logo from '../components/Logo.html'

    const store = getContext('store')
    const action = getContext('action')

    // states
    // invalid, ready, enter_code, submit, error
    const [
        READY,
        CODE_START,
        CODE_SUCCESS,
        SUBMIT_START,
        SUBMIT_SUCCESS,
        INVALID,
        ERROR,
    ] = [
        'READY',
        'CODE_START',
        'CODE_SUCCESS',
        'SUBMIT_START',
        'SUBMIT_SUCCESS',
        'INVALID',
        'ERROR',
    ]

    let email = ''
    let account = ''
    let code = ''
    let state = READY

    $: {
        if (state === ERROR) {
            email = ''
            code = ''
        }
    }

    onMount(() => {
        account = '18432685624'
    })

    async function sendCode() {
        state = CODE_START
        const res = await action('doAuthStart')({ email })
        if (!res) {
            return (state = ERROR)
        }
        // transition state to code
        state = CODE_SUCCESS
    }

    async function login() {
        state = SUBMIT_START
        const res = await action('doAuthVerify')({ email, code })

        if (!res) {
            return (state = ERROR)
        }

        // transition state to submit
        state = SUBMIT_SUCCESS
    }

    // pure functions
    function equals(a, b) {
        return a === b
    }
    function or(a, b) {
        return a || b
    }
    function length(a, n) {
        return a.length === n
    }
    function not(a) {
        return !a
    }
</script>

<style>
    section {
        height: 100%;
        display: grid;
        grid-template-areas:
            'header'
            'main'
            'footer';

        grid-template-rows: 72px auto 48px;
    }

    header {
        grid-area: header;
    }

    header > div {
        height: 100%;
    }
    .title {
        color: white;
    }

    .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    main {
        grid-area: main;
        display: flex;
        flex-direction: column;
    }

    .box {
        width: 350px;
    }

    footer {
        grid-area: footer;
    }
</style>

<section>
    <header class="navbar is-primary center">
        <div class="navbar-brand center">
            <h1 class="title">Citibot Notify</h1>
        </div>
    </header>
    <main class="center">
        <Logo />
        <h1 class="title">Citibot Notify</h1>
        <div class="box">
            {#if equals(state, READY)}
                <p>Login with your city email address.</p>
                <div class="field">
                    <label for="email" class="label">Email</label>
                    <div class="control">
                        <input
                            type="email"
                            class="input is-primary"
                            bind:value={email} />
                    </div>

                </div>
                <button
                    disabled={equals(state, CODE_START)}
                    class="button is-primary"
                    type="button"
                    on:click={sendCode}>
                    Send
                </button>
            {/if}
            {#if equals(state, CODE_SUCCESS)}
                <p>
                    Check your email for a message with a six digit code; enter
                    the code here to continue.
                </p>
                <div class="field">
                    <label for="code" class="label">Code</label>
                    <div class="control">
                        <input
                            type="text"
                            class="input is-primary"
                            bind:value={code} />
                    </div>
                </div>
                <button
                    disabled={equals(state, SUBMIT_START)}
                    class="button is-primary"
                    type="button"
                    on:click={login}>
                    Login
                </button>
            {/if}
            {#if equals(state, INVALID)}
                <p>Unable to initialize sign in</p>
                <p>
                    Text 'hello' to Citibot and choose citibot notify
                    preferences
                </p>
                <p>
                    If you continue to see this message, contact citibot
                    support: support@citibot.io
                </p>
            {/if}
            {#if equals(state, SUBMIT_SUCCESS)}
                <p>Logging In</p>
            {/if}
            {#if equals(state, ERROR)}
                <h2>Error</h2>
                <p>An error has occured with the system, please try again.</p>
                <p>Please try entering the code again</p>
                <button
                    class="button is-primary"
                    type="button"
                    on:click={() => (state = READY)}>
                    Try Again
                </button>
            {/if}
        </div>
    </main>
    <footer class="center">
        <p>All rights reserved. &copy; Citibot, LLC 2020</p>
    </footer>
</section>
