import { composeBundles, createCacheBundle } from 'redux-bundler'

import routes from './routes'
import app from './app'
import auth from './auth'
import blasts from './blasts'
import categories from './categories'
import notifications from './notifications'
import cache from '../utils/cache'
import account from './account'
import citizens from './citizens'
import users from './users'

export default composeBundles(
    app,
    auth,
    routes,
    blasts,
    categories,
    notifications,
    account,
    citizens,
    users,
    createCacheBundle(cache.set)
)
