<script>
    import { onMount, getContext } from 'svelte'

    const action = getContext('action')

    onMount(async () => {
        const loggedIn = await action('doAuthLogin')()

        if (loggedIn) action('doUpdateUrl')('/blasts')
    })
</script>

<section>
    <p>Logging In</p>
</section>
