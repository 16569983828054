<figure>
  <img
    src="https://images.citibot.io/favicons/favicon-32x32.png"
    alt="citibot"
  />
</figure>
<style>
  figure {
    /** default height so that when loading the image will fill the
    defined space **/
    height: 128px;
    width: 128px;
  }
</style>
