<script>
    import { setContext } from 'svelte'
    import { createStore } from './store'
    import navHelper from './lib/nav-helper'
    // get data from cache
    export let initialData = {}

    let { store, action } = createStore(initialData)
    // dynamically set page based on router bundle
    $: page = $store.route
    // set context from global store
    setContext('store', store)
    setContext('action', action)
</script>

<style>
    :global(body) {
        height: 100vh;
        width: 100%;
    }
    div {
        height: 100%;
    }
</style>

<div on:click={navHelper(action('doUpdateUrl'))}>
    <svelte:component this={page} />
</div>

