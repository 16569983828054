const initial = {
    loading: false,
    data: {},
}
//
const [ACCOUNT_LOADING, ACCOUNT_ERROR, ACCOUNT_FETCH_SUCCESS] = [
    'ACCOUNT_LOADING',
    'ACCOUNT_ERROR',
    'ACCOUNT_FETCH_SUCCESS',
]

export default {
    name: 'account',
    doGetAccount() {
        return async ({ dispatch, api, store }) => {
            // dispatch starting
            dispatch({ type: ACCOUNT_LOADING })
            const accountNo = store.selectAccountNo()
            const result = await api.get(`/accounts/${accountNo}`)
            // dispatch success
            if (result.error) {
                dispatch({ type: ACCOUNT_ERROR, payload: result.error })
                return Promise.resolve(false)
            }
            dispatch({ type: ACCOUNT_FETCH_SUCCESS, payload: result })
            return Promise.resolve(true)
        }
    },
    selectAccount(state) {
        return state.account.data
    },
    reducer(state = initial, { type, payload }) {
        if (type === ACCOUNT_LOADING) {
            return merge(state, { loading: true })
        }
        if (type === ACCOUNT_ERROR) {
            return merge(state, { loading: false, error: payload })
        }
        if (type === ACCOUNT_FETCH_SUCCESS) {
            return merge(state, {
                loading: false,
                data: payload,
            })
        }
        return state
    },
    persistActions: [ACCOUNT_FETCH_SUCCESS],
}

function merge(...args) {
    return Object.assign({}, ...args)
}
