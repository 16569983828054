<script>
    import { tick, getContext, onMount } from 'svelte'
    import { slide } from 'svelte/transition'

    import { isEmpty, not } from 'ramda'

    const action = getContext('action')
    const store = getContext('store')

    // state
    let selected = []
    let account = $store.accountNo
    let email = ''
    let admin = false
    let error = ''
    $: users = $store.users
    let accountDoc = $store.account

    const [READY, SUBMITTING, ERROR, SUCCESS] = [
        'READY',
        'SUBMITTING',
        'ERROR',
        'SUCCESS',
    ]

    let componentState = READY

    onMount(async () => {
        const res = await action('doGetUsers')()
        if (!res) {
            componentState = ERROR

            return
        }
    })

    $: charCount = email.length

    $: {
        if (componentState === ERROR) {
            alert(error)
            componentState = READY
        }
        if (componentState === SUCCESS) {
            tick()
            action('doUpdateUrl')('/success')
        }
    }

    async function doSubmit() {
        if (charCount === 0) {
            error = 'You must enter an email'
            componentState = ERROR
            return
        }

        componentState = SUBMITTING

        const success = await action('doAddUser')({ email, admin })
        if (!success) {
            // TODO: get error from users bundle
            error = 'Sorry, an error occured.'
            componentState = ERROR
            return
        } else {
            tick()
            email = ''
            admin = false
            componentState = READY
        }
    }

    async function doDelete() {
        componentState = SUBMITTING
        const confirmDelete = confirm(
            'Are you sure you want to delete this users?'
        )

        if (confirmDelete) {
            const success = await action('doDeleteUser')(selected)

            if (!success) {
                // TODO: get error from users bundle
                error = 'Sorry, an error occured.'
                componentState = ERROR
                return
            } else {
                selected = []
                tick()
                componentState = READY
            }
        } else {
            componentState = READY
        }
    }
</script>

<style>
    .category-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-wrap: wrap;
    }

    section {
        height: 100%;
        display: grid;
        grid-template-areas:
            'header'
            'main'
            'footer';

        grid-template-rows: 72px auto 48px;
    }

    header {
        grid-area: header;
    }

    header > div {
        height: 100%;
    }
    .title {
        color: white;
    }

    .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    main {
        grid-area: main;
        display: flex;
        flex-direction: column;
    }

    .box {
        margin-top: 32px;
        max-width: 750px;
    }

    @media (max-width: 500px) {
        .box {
            max-width: 400px;
        }
    }

    footer {
        grid-area: footer;
    }

    input {
        border-color: rgba(0, 0, 0, 0.04);
    }
</style>

<section>
    <header class="navbar is-primary center">
        <div class="navbar-brand center">
            <h1 class="title">{accountDoc.description}</h1>
        </div>
    </header>
    <main class="center">
        <div class="content">
            <div class="box">
                <h3 class="subtitle">Admin Panel</h3>
                <p>
                    You can add users here! Just type in the email address of
                    the user you wish to add.
                </p>
                <p>
                    You can also give admin permissions.
                    <br />
                    The asterisk(*) denotes a user with admin rights
                </p>

                <form on:submit|preventDefault={doSubmit}>
                    <div class="field">
                        <label for="categories" class="label">
                            Current Users
                        </label>
                        <div class="category-list">
                            {#each users as user}
                                <label>
                                    <input
                                        type="checkbox"
                                        value={user.user_id}
                                        bind:group={selected} />
                                    {#if user.app_metadata.isAdmin}
                                        <span>{user.email}*</span>
                                    {:else}
                                        <span>{user.email}</span>
                                    {/if}
                                </label>
                            {/each}

                        </div>
                        {#if selected.length > 0}
                            <div transition:slide>
                                <hr />
                                <span>Delete Selected Users</span>
                                <div>
                                    <button
                                        disabled={componentState === SUBMITTING}
                                        class="button is-danger"
                                        type="button"
                                        on:click|preventDefault={doDelete}>
                                        Delete
                                    </button>
                                </div>

                            </div>
                        {/if}
                    </div>
                    <div class="columns">
                        <div class="column is-half">
                            <div class="field">
                                <label for="email" class="label">Email</label>
                                <input
                                    class="input"
                                    type="email"
                                    bind:value={email}
                                    placeholder="example@email.com" />
                            </div>
                            <label for="admin" class="checkbox">
                                <input bind:checked={admin} type="checkbox" />
                                make user admin
                            </label>
                        </div>

                    </div>
                    <div>
                        <button
                            disabled={componentState === SUBMITTING}
                            class="button is-primary"
                            type="submit">
                            Add
                        </button>
                        <a href="/blasts" class="button">Home</a>
                    </div>
                </form>
            </div>
        </div>
    </main>

    <footer class="center">
        <p>All Rights Reserved. &copy; Citibot, LLC 2020</p>
    </footer>
</section>
