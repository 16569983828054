<script>
    import Logo from '../components/Logo.html'
</script>

<style>
    section {
        height: 100%;
        display: grid;
        grid-template-areas:
            'header'
            'main'
            'footer';

        grid-template-rows: 72px auto 48px;
    }

    header {
        grid-area: header;
    }

    header > div {
        height: 100%;
    }
    .title {
        color: white;
    }

    .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    main {
        grid-area: main;
        display: flex;
        flex-direction: column;
    }

    .box {
        width: 350px;
    }

    footer {
        grid-area: footer;
    }
</style>

<section>
    <header class="navbar is-primary center">
        <div class="navbar-brand center">
            <h1 class="title">Error</h1>
        </div>
    </header>
    <main class="center">
        <Logo />
        <div class="content">
            <h3 class="center">Page Not Found</h3>
            <div class="box">
                <p>You trying to access a page that does not exist.</p>
                <a class="button center" href="/">Close</a>
            </div>
        </div>
    </main>
    <footer class="center">
        <p>All Rights Reserved. &copy; Citibot, LLC 2020</p>
    </footer>
</section>
