<script>
    import { tick, getContext } from 'svelte'
    import { pathOr } from 'ramda'

    const action = getContext('action')
    const store = getContext('store')

    // state
    let to = ''
    let message = ''
    let error = ''
    let account = $store.accountNo
    let accountDoc = $store.account

    //states component can be in
    // ready, submitting, error, success
    const [READY, SUBMITTING, ERROR, SUCCESS] = [
        'READY',
        'SUBMITTING',
        'ERROR',
        'SUCCESS',
    ]

    let componentState = READY

    $: charCount = message.length
    $: {
        if (componentState === ERROR) {
            alert(error)
            componentState = READY
        }
        if (componentState === SUCCESS) {
            tick()
            action('doUpdateUrl')('/blasts')
        }
    }

    async function doSubmit() {
        if (charCount === 0) {
            error = 'Cannot send empty message'
            componentState = ERROR
            return
        }
        // change component state to submitting
        componentState = SUBMITTING

        const success = await action('doNotify')(to, enhance(message, account))

        if (!success) {
            componentState = ERROR
            // TODO: get error from blast bundle?
        } else {
            componentState = SUCCESS
        }
    }

    function enhance(msg, account) {
        const enhancement = pathOr(
            null,
            ['notifyData', 'blastEnhancement'],
            accountDoc
        )
        if (enhancement) {
            return `${msg}

${enhancement}`
        } else {
            return `${msg}`
        }
    }
</script>

<style>
    section {
        height: 100%;
        display: grid;
        grid-template-areas:
            'header'
            'main'
            'footer';

        grid-template-rows: 72px auto 48px;
    }

    header {
        grid-area: header;
    }

    header > div {
        height: 100%;
    }
    .title {
        color: white;
    }

    .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    main {
        grid-area: main;
        display: flex;
        flex-direction: column;
    }

    .box {
        margin-top: 32px;
        max-width: 750px;
    }

    @media (max-width: 500px) {
        .box {
            max-width: 400px;
        }
    }

    footer {
        grid-area: footer;
    }

    textarea {
        border-color: rgba(0, 0, 0, 0.04);
    }
</style>

<section>
    <header class="navbar is-primary center">
        <div class="navbar-brand center">
            <h1 class="title">{accountDoc.description}</h1>
        </div>
    </header>
    <main class="center">
        <div class="content">
            <div class="box">
                <h3 class="subtitle">Send Direct Message</h3>
                <p>
                    In order to send a direct notification, you to specify a
                    phone number.
                </p>
                <p>
                    Next, you will want to write the message. You can include
                    links to additional resources, but you should aim to keep
                    the message under 160 characters.
                </p>
                <p>
                    If you exceed that limit, you message will be split into
                    multiple texts.
                </p>

                <form on:submit|preventDefault={doSubmit}>
                    <div class="field">
                        <div class="control">
                            <label for="phone" class="label">Phone</label>
                            <input
                                id="phone"
                                class="input"
                                type="text"
                                bind:value={to}
                                placeholder="eg 8881231234" />
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-half">
                            <div class="field">
                                <label for="message" class="label">
                                    Message
                                </label>
                                <textarea
                                    id="message"
                                    class="textarea"
                                    bind:value={message} />
                                <span>{charCount} characters entered.</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button
                            disabled={componentState === SUBMITTING}
                            class="button is-primary"
                            type="submit">
                            Send
                        </button>
                        <a href="/blasts" class="button">Cancel</a>
                    </div>
                </form>
            </div>
        </div>
    </main>
    <footer class="center">
        <p>All Rights Reserved. &copy; Citibot, LLC 2020</p>
    </footer>
</section>
