export default [
  {
    key: 'transportation',
    title: 'Transportation',
    desc: 'Transportation'
  },
  {
    key: 'child-family-services',
    title: 'Child/Family Services',
    desc: 'Child/Family'
  },
  {
    key: 'legal-rights',
    title: 'Legal Rights',
    desc: 'Legal Rights'
  },
  {
    key: 'accessing-affordable-housing',
    title: 'Accessing Affordable Housing',
    desc: 'Accessing Affordable Housing'
  },
  {
    key: 'financial-assistance',
    title: 'Financial Assistance',
    desc: 'Financial Assistance'
  },
  {
    key: 'community-engagement',
    title: 'Community-Engagement',
    desc: 'Community-Engagement'
  },
  {
    key: 'other-city-news',
    title: 'Other City News',
    desc: 'Other City News'
  }
]
